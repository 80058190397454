.contactContainer {
  padding-bottom: 40px;
}

.contactImage {
  width: 450px;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contactBlurb {
  max-width: 80%;
  margin: auto;
  padding: 10px;
}

.email {
  
}