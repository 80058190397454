.featured_display{
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featured__links{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding: 0 16px;
}

.featured__links a {
  padding: 0 5px;
}

.featured__image {
  max-width: 100%;
  max-height: 500px;
  padding: 8px 16px;
}

#modalContainer {
  height: 100%;
  width: 100%;
}

.Modal-Backdrop {
  background-color: rgba(0,0,0,.8);
  position: absolute;
  width: 100%;
  height:100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Modal-Contents{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.Modal-Contents .Modal{
  max-height: 80%;
  max-width: 80%;
  background-color: white;
}

.Modal-Close{
  position: absolute;
  top: 25px;
  right: 40px;
  font-size: 45px;
  line-height: 30px;
  color: white;
  cursor: pointer;
}


@media screen and (max-width: 620px) {
  .Modal-Close{
    top: 10px;
    right: 10px;
  }
}