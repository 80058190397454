html {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App-module-vknX2G-App {
  text-align: center;
}

.App-module-vknX2G-App-logo {
  height: 80px;
  animation: 20s linear infinite App-module-vknX2G-App-logo-spin;
}

.App-module-vknX2G-App-header {
  padding: 20px;
  font-family: Homemade Apple;
}

.App-module-vknX2G-Main-Link {
  color: #000;
  text-decoration: none;
}

.App-module-vknX2G-App-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3.5em;
}

@media (max-width: 525px) {
  .App-module-vknX2G-App-title {
    font-size: 2em;
  }
}

.App-module-vknX2G-App-intro {
  font-size: large;
}

@keyframes App-module-vknX2G-App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.featured_display {
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  display: flex;
}

.featured__links {
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;
  padding: 0 16px;
  display: flex;
}

.featured__links a {
  padding: 0 5px;
}

.featured__image {
  max-width: 100%;
  max-height: 500px;
  padding: 8px 16px;
}

#modalContainer {
  height: 100%;
  width: 100%;
}

.Modal-Backdrop {
  width: 100%;
  height: 100%;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.Modal-Contents {
  height: 100%;
  width: 100%;
  place-content: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Modal-Contents .Modal {
  max-height: 80%;
  max-width: 80%;
  background-color: #fff;
}

.Modal-Close {
  color: #fff;
  cursor: pointer;
  font-size: 45px;
  line-height: 30px;
  position: absolute;
  top: 25px;
  right: 40px;
}

@media screen and (max-width: 620px) {
  .Modal-Close {
    top: 10px;
    right: 10px;
  }
}

.contactContainer {
  padding-bottom: 40px;
}

.contactImage {
  width: 450px;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.contactBlurb {
  max-width: 80%;
  margin: auto;
  padding: 10px;
}

.MainList-module-Mp3mWG-container {
  width: 100%;
  max-width: 1270px;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  padding: 0 5px 50px;
  display: flex;
}

.MainList-module-Mp3mWG-imageLink {
  width: calc(25% - 20px);
  margin: 10px;
}

.MainList-module-Mp3mWG-thumbnail {
  width: 100%;
  background-size: cover;
  padding-top: 100%;
}

@media (min-width: 1259px) {
  .MainList-module-Mp3mWG-container:after {
    content: "";
    flex: auto;
  }
}

@media (max-width: 1259px) {
  .MainList-module-Mp3mWG-container {
    max-width: 1070px;
    padding: 0 5px;
  }

  .MainList-module-Mp3mWG-container:after {
    content: "";
    flex: auto;
  }

  .MainList-module-Mp3mWG-imageLink {
    width: calc(33% - 20px);
  }
}

@media (max-width: 800px) {
  .MainList-module-Mp3mWG-container {
    justify-content: center;
  }

  .MainList-module-Mp3mWG-imageLink {
    width: calc(50% - 4px);
    margin: 2px 0;
  }

  .MainList-module-Mp3mWG-imageLink:nth-child(2n+1) {
    margin-right: 4px;
  }

  .MainList-module-Mp3mWG-imageLink:nth-child(2n) {
    margin-left: 4px;
  }
}

.MainList-module-Mp3mWG-filler {
  display: block;
}

.Tabs-module-OGfQhW-tabContainer {
  margin-bottom: 20px;
}

.Tabs-module-OGfQhW-tab {
  color: #000;
  margin: 10px;
  text-decoration: none;
}

.Tabs-module-OGfQhW-current.Tabs-module-OGfQhW-tab {
  font-weight: 700;
}

.Tabs-module-OGfQhW-menuButton {
  display: none;
}

@media screen and (max-width: 550px) {
  .Tabs-module-OGfQhW-menuButton {
    margin-bottom: 16px;
    font-size: 18px;
    display: block;
  }

  .Tabs-module-OGfQhW-menuButton.Tabs-module-OGfQhW-open {
    border-bottom: 1px solid #0000004d;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  .Tabs-module-OGfQhW-tabContainer {
    flex-direction: column;
    display: flex;
  }

  .Tabs-module-OGfQhW-tabContainer.Tabs-module-OGfQhW-hidden {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/*# sourceMappingURL=index.8c223052.css.map */
